/*! Michael Pumo - Web Development. http://michaelpumo.com */

(function($, Modernizr) {

    'use strict';

    var state = {

        swiper: false,

        setOrGetDevice: function(device) {

            if (typeof(device) === 'undefined') {
                var mq = Modernizr.mq('only screen and (min-width: 769px)') ? 'desktop' : 'mobile';
                device = mq;
            }

            return device;

        },

        device: function() {

            return state.setOrGetDevice();

        }

    };

    var cache = {

        $window: $(window),
        $html: $('html'),
        $main: $('#main'),
        $swiper: $('.swiper-container'),
        $swiperElements: $('.swiper-container, .swiper-wrapper, .swiper-slide'),
        $swiperPrevious: $('#swiper-previous'),
        $swiperNext: $('#swiper-next'),
        $contact: $('#contact'),
        $home: $('#home'),
        $page: $('#page')

    };

    var swiper;

    var app = {

        init: function(resize) {

            var event = resize || false;

            app.swiper();

            if(!event) {
                app.show();
                app.video();
                app.contact();
            }

        },

        show: function() {

            cache.$main.addClass('show');

        },

        swiper: function() {

            if(cache.$swiper.length) {

                if(state.device() === 'desktop' && !state.swiper) {

                    swiper = cache.$swiper.swiper({
                        parallax: false,
                        initialSlide: 0,
                        direction: 'horizontal',
                        loop: true,
                        autoplay: false,
                        speed: 1600,
                        preventClicks: true
                    });

                    swiper.on('slideChangeEnd', function(swiperInstance) {

                        var $slide = $(swiperInstance.slides[swiper.activeIndex]),
                            color = $slide.data('color');

                        if(color === 'dark') {
                            cache.$main.removeClass('light');
                            cache.$main.addClass('dark');
                        } else {
                            cache.$main.removeClass('dark');
                            cache.$main.addClass('light');
                        }

                    });

                    cache.$swiperPrevious.on('click', function() {
                        swiper.slidePrev();
                    });

                    cache.$swiperNext.on('click', function() {
                        swiper.slideNext();
                    });

                    state.swiper = true;

                } else if(state.device() === 'mobile' && state.swiper) {

                    swiper.destroy();
                    swiper = undefined;
                    cache.$swiperElements.removeAttr('style');
                    state.swiper = false;

                }

            }

        },

        _videoClose: function() {

            $('.project__item').removeClass('playing');

            setTimeout(function() {
                cache.$main.removeClass('playing');
            }, 300);

        },

        video: function() {

            var $vimeo = $('.vimeo');

            if($vimeo.length) {

                $vimeo.each(function(i) {

                    var index = i;

                    $(this).magnificPopup({

                        closeBtnInside: false,
                        showCloseBtn: false,
                        disableOn: 0,
                        type: 'iframe',
                        iframe: {

                            markup: '<div class="mfp-iframe-scaler">' +
                                        '<iframe id="vimeo-video-' + index + '" class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                                    '</div>',

                            patterns: {

                                youtube: {
                                    index: 'youtube.com', 
                                    id: 'v=', 
                                    src: '//www.youtube.com/embed/%id%?autoplay=1'
                                },

                                vimeo: {
                                    index: 'vimeo.com/',
                                    id: '/',
                                    src: '//player.vimeo.com/video/%id%'
                                    //?autoplay=1
                                },

                                gmaps: {
                                    index: '//maps.google.',
                                    src: '%id%&output=embed'
                                }

                            }

                        },
                        mainClass: 'mfp-fade',
                        removalDelay: 0,
                        preloader: true,
                        fixedContentPos: false,
                        callbacks: {

                            beforeOpen: function() {
                                cache.$main.addClass('playing');
                            },

                            open: function() {

                                $(this.currItem.el).closest('.project__item').toggleClass('playing');

                                setTimeout(function() {

                                    try {

                                        var iframe = $('#vimeo-video-' + index);

                                        if( iframe.length ) {

                                            var player = $f(iframe[0]);
                                        
                                            player.addEvent('ready', function() {
                                                player.addEvent('finish', function() {
                                                    $.magnificPopup.close();
                                                });
                                            });

                                        }

                                    } catch(error) {

                                        window.console.log('Could not attach event.');

                                    }

                                }, 300);

                            },

                            close: function() {
                                app._videoClose();
                            }

                        }

                    });

                });

            }

        },

        contact: function() {

            cache.$contact.on('click', function() {
                cache.$page.addClass('open');
            });

            cache.$home.on('click', function() {
                cache.$page.removeClass('open');
            });

        }

    };

    $(function() {
        app.init();
    });

    cache.$window.on('resize', function() {

        var mq = Modernizr.mq('only screen and (min-width: 769px)') ? 'desktop' : 'mobile';
        state.setOrGetDevice(mq);
        app.init(true);

    });

})(window.jQuery, window.Modernizr);


